<div class="container-fluid px-1 py-5 mx-auto">
    <div class="row d-flex justify-content-center">
        <div class="col-xl-10 col-lg-6 col-md-7">
            <div class="card2 b-0">

                <!-- Depois de enviar o form -->
                <div *ngIf="(enviado === true)">
                    <img src="https://www.gedaim.com/images/logo-gedaim-color.png">
                    <br><br>
                    <span class="font-weight-bold">Ficha enviada com sucesso</span>

                    <p style="padding-top: 15px;">
                        Verifique seu e-mail, pois nele receberá uma cópia da ficha enviada. <br>
                        Em breve, a imobiliária deverá entrar em contato para dar prosseguimento ao processo.
                    </p>

                    <small style="padding-top: 15px;">
                        Desenvolvido e mantido por <a href="https://goyazsistemas.com.br" target="_blank">Goyaz Sistemas e Comércio Ltda</a>.
                    </small>
                </div>
                <!-- Depois de enviar o form -->

                <!-- Caso não tenha passado o parâmetro da imobiliária na URL -->
                <div *ngIf="(enviado === false) && ((imobiliariaID == null) || (imobiliariaID === ''))">
                    <img src="https://www.gedaim.com/images/logo-gedaim-color.png">
                    <br><br>
                    <span class="font-weight-bold">A imobiliária não foi informada</span>

                    <p style="padding-top: 15px;">
                        Para a utilzação deste app, é necessário que informe a imobiliária para a qual se destina seu cadastro.<br>
                        Recomendamos que acesse o site da imobiliária e clique no link referente à ficha de cadastro.
                    </p>

                    <small style="padding-top: 15px;">
                        Desenvolvido e mantido por <a href="https://gedaim.com" target="_blank">Goyaz Sistemas e Comércio Ltda</a>.
                    </small>
                </div>
                <!-- Caso não tenha passado o parâmetro da imobiliária na URL -->

                <form [formGroup]="formCadastro" (ngSubmit)="enviaForm()" *ngIf="(enviado === false) && ((imobiliariaID !== null) && (imobiliariaID !== ''))">
                    <aw-wizard class="naoImprime">
                        <aw-wizard-step stepTitle="" (stepEnter)="etapaSeta('Tipo')">
                            <div class="form-card">
                                <div class="row">
                                    <div class="col-xl-4 col-12">
                                        <div id='Inquilino' class="card-block text-center radio selected" (click)="desmarcaOpcoes('Inquilino')">
                                            <div class="image-icon">
                                                <img class="icon icon1" src="./assets/img/inquilino.png">
                                            </div>
                                            <p class="sub-desc">Inquilino</p>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-12">
                                        <div id='Fiador' class="card-block text-center radio" (click)="desmarcaOpcoes('Fiador')">
                                            <div class="image-icon">
                                                <img class="icon icon1 fit-image" src="./assets/img/fiador.png">
                                            </div>
                                            <p class="sub-desc">Fiador</p>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-12">
                                        <div id="Locador" class="card-block text-center radio" (click)="desmarcaOpcoes('Locador')">
                                            <div class="image-icon">
                                                <img class="icon icon1 fit-image" src="./assets/img/locador.png">
                                            </div>
                                            <p class="sub-desc">Proprietário</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="padding-top: 15px; padding-left: 25px;">
                                    <p style="text-align: justify;">
                                        <fa-icon [icon]="faSave" class="text-primary"></fa-icon>&nbsp;
                                        Salva os dados e permite continuar o preenchimento em outro momento.
                                    </p>
                                </div>

                                <div class="row" style="padding-left: 25px;">
                                    <p style="text-align: justify;">
                                        <fa-icon [icon]="faTrash" class="text-danger"></fa-icon>&nbsp;
                                        Limpa os dados para recomeçar o preenchimento.
                                    </p>
                                </div>
                            </div>

                        </aw-wizard-step>

                        <!-- Dados Básicos -->
                        <aw-wizard-step stepTitle="" [canExit]="avanca" (stepEnter)="etapaSeta('DadosBasicos')">
                            <div class="card rounded-0">
                                <div class="card-header">
                                    <span>Dados Básicos</span><br>
                                    <small class="text-muted">Informações cadastrais básicas.</small>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Tipo de Pessoa *</mat-label>
                                                <mat-select id="cbTipoPessoa" name="cbTipoPessoa" formControlName="tipo_pessoa">
                                                    <mat-option value="Física" selected>Física</mat-option>
                                                    <mat-option value="Jurídica">Jurídica</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-9">
                                            
                                        </div>

                                        <div class="col-xl-5 col-12">
                                            <mat-form-field class="input100" *ngIf="this.formCadastro.controls['tipo_pessoa'].value === 'Física'">
                                                <input matInput id="edtNome" name="edtNome" type="text" formControlName="nome" placeholder="Nome Completo *">
                                            </mat-form-field>

                                            <mat-form-field class="input100" *ngIf="this.formCadastro.controls['tipo_pessoa'].value === 'Jurídica'">
                                                <input matInput id="edtNome" name="edtNome" type="text" formControlName="nome" placeholder="Razão Social *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="mail" type="email" formControlName="mail" placeholder="E-Mail *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="telefone" placeholder="Telefone" name="telefone" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="celular" placeholder="Celular *" name="celular" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-5 col-12">
                                            <div class="row">
                                                <div class="col-xl-6 col-12">
                                                    <mat-slide-toggle formControlName="telegram" style="padding-top: 17px;">Telegram</mat-slide-toggle>
                                                </div>

                                                <div class="col-xl-6 col-12">
                                                    <mat-slide-toggle formControlName="whatsapp" style="padding-top: 17px;">WhatsApp</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Preferência de Contato *</mat-label>
                                                <mat-select id="cbPreferenciaContato" name="cbPreferenciaContato" formControlName="preferencia_contato">
                                                    <mat-option value="Ligação Telefônica">Ligação Telefônica</mat-option>
                                                    <mat-option value="Mensagem via Celular">Mensagem via Celular</mat-option>
                                                    <mat-option value="Mensagem de E-Mail">Mensagem de E-Mail</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="input100">
                                                <textarea matInput formControlName="endereco" placeholder="Endereço Completo *" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aw-wizard-step>

                        <!-- Dados Complementares -->
                        <aw-wizard-step stepTitle="" [canExit]="avanca" (stepEnter)="etapaSeta('DadosComplementares')">
                            <div class="card rounded-0" *ngIf="this.formCadastro.controls['tipo_pessoa'].value === 'Física'">
                                <div class="card-header">
                                    <span>Dados Complementares</span><br>
                                    <small class="text-muted">Informações cadastrais complementares para pessoa física.</small>
                                </div>

                                <div class="card-body">
                                    <!-- Pessoa Física -->
                                    <div class="row">
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="cpf" type="text" formControlName="cpf" placeholder="CPF *" mask="000.000.000-00">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="documento_identificacao" placeholder="Documento de Identificação *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="date" formControlName="nascimento" placeholder="Nascimento *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-2 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Gênero *</mat-label>
                                                <mat-select formControlName="genero">
                                                    <mat-option value="Masculino">Masculino</mat-option>
                                                    <mat-option value="Feminino">Feminino</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Estado Civil *</mat-label>
                                                <mat-select formControlName="estado_civil">
                                                    <mat-option value="Solteiro(a)">Solteiro(a)</mat-option>
                                                    <mat-option value="Casado(a)">Casado(a)</mat-option>
                                                    <mat-option value="Divorciado(a)">Divorciado(a)</mat-option>
                                                    <mat-option value="Seperado(a) Judicialmente">Seperado(a) Judicialmente</mat-option>
                                                    <mat-option value="Viúvo(a)">Viúvo(a)</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="nacionalidade" placeholder="Nacionalidade *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="profissao" placeholder="Profissão *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="trabalho_empresa" placeholder="Empresa onde Trabalha *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="trabalho_telefone" placeholder="Telefone do Trabalho *" name="trabalho_telefone" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="trabalho_tempo" placeholder="Tempo de Trabalho *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="renda_mensal" placeholder="Renda Mensal *" mask="separator.2" prefix="R$ " thousandSeparator="." separator=",">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="outras_rendas" placeholder="Outras Rendas" mask="separator.2" prefix="R$ " thousandSeparator="." separator=",">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="input100">
                                                <textarea matInput formControlName="trabalho_endereco" placeholder="Endereço do Trabalho" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Pessoa Jurídica -->
                            <div class="card rounded-0" *ngIf="formCadastro.controls['tipo_pessoa'].value === 'Jurídica'">
                                <div class="card-header">
                                    <span>Dados Complementares</span><br>
                                    <small class="text-muted">Informações cadastrais complementares para pessoa jurídica.</small>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="cnpj" type="text" formControlName="cnpj" placeholder="CNPJ *" mask="00.000.000/0000-00">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput id="edtInquilinoiInscEstadual" name="edtInquilinoiInscEstadual" type="text" formControlName="insc_estadual" placeholder="Inscrição Estadual">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput id="edtInquilinoInscMunicipal" name="edtInquilinoInscMunicipal" type="text" formControlName="insc_municipal" placeholder="Inscrição Municipal">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Representante Legal -->
                            <br *ngIf="formCadastro.controls['tipo_pessoa'].value === 'Jurídica'">

                            <div class="card rounded-0" *ngIf="formCadastro.controls['tipo_pessoa'].value === 'Jurídica'">
                                <div class="card-header">
                                    <span>Representante Legal</span><br>
                                    <small class="text-muted">Informações do sócio representante da empresa.</small>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-5 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="rep_nome" placeholder="Nome Completo *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="rep_mail" type="email" formControlName="rep_mail" placeholder="E-Mail *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="rep_telefone" placeholder="Telefone" name="rep_telefone" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="rep_celular" placeholder="Celular *" name="rep_celular" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-5 col-12">
                                            <div class="row">
                                                <div class="col-xl-6 col-12">
                                                    <mat-slide-toggle formControlName="rep_telegram" style="padding-top: 17px;">Telegram</mat-slide-toggle>
                                                </div>

                                                <div class="col-xl-6 col-12">
                                                    <mat-slide-toggle formControlName="rep_whatsapp" style="padding-top: 17px;">WhatsApp</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Preferência de Contato *</mat-label>
                                                <mat-select formControlName="rep_preferencia_contato">
                                                    <mat-option value="Ligação Telefônica">Ligação Telefônica</mat-option>
                                                    <mat-option value="Mensagem via Celular">Mensagem via Celular</mat-option>
                                                    <mat-option value="Mensagem de E-Mail">Mensagem de E-Mail</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="input100">
                                                <textarea matInput formControlName="rep_endereco" placeholder="Endereço Completo *" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="rep_cpf" type="text" formControlName="rep_cpf" placeholder="CPF *" mask="000.000.000-00">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="rep_documento_identificacao" placeholder="Documento de Indentificação *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="date" formControlName="rep_nascimento" placeholder="Nascimento *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-2 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Gênero *</mat-label>
                                                <mat-select formControlName="rep_genero">
                                                    <mat-option value="Masculino">Masculino</mat-option>
                                                    <mat-option value="Feminino">Feminino</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Estado Civil *</mat-label>
                                                <mat-select formControlName="rep_estado_civil">
                                                    <mat-option value="Solteiro(a)">Solteiro(a)</mat-option>
                                                    <mat-option value="Casado(a)">Casado(a)</mat-option>
                                                    <mat-option value="Divorciado(a)">Divorciado(a)</mat-option>
                                                    <mat-option value="Seperado(a) Judicialmente">Seperado(a) Judicialmente</mat-option>
                                                    <mat-option value="Viúvo(a)">Viúvo(a)</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="rep_nacionalidade" placeholder="Nacionalidade *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="rep_profissao" placeholder="Profissão *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="rep_renda_mensal" placeholder="Renda Mensal *" mask="separator.2" prefix="R$ " thousandSeparator="." separator=",">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="rep_outras_rendas" placeholder="Outras Rendas" mask="separator.2" prefix="R$ " thousandSeparator="." separator=",">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Dados do Cônjuge -->
                            <br *ngIf=  "
                                            (this.formCadastro.controls['tipo_pessoa'].value === 'Física' && formCadastro.controls['estado_civil'].value === 'Casado(a)') ||
                                            (this.formCadastro.controls['tipo_pessoa'].value === 'Jurídica' && formCadastro.controls['rep_estado_civil'].value === 'Casado(a)')
                            ">

                            <div class= "card rounded-0" *ngIf= "
                                                                    (this.formCadastro.controls['tipo_pessoa'].value === 'Física' && formCadastro.controls['estado_civil'].value === 'Casado(a)') ||
                                                                    (this.formCadastro.controls['tipo_pessoa'].value === 'Jurídica' && formCadastro.controls['rep_estado_civil'].value === 'Casado(a)')
                            ">
                                <div class="card-header">
                                    <span>Dados do Cônjuge</span><br>
                                    <small class="text-muted">Informações cadastrais do cônjuge do inquilino.</small>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-5 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_nome" placeholder="Nome Completo *">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="conj_mail" type="email" formControlName="conj_mail" placeholder="E-Mail">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="conj_celular" placeholder="Celular *" name="conj_celular" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-5 col-12">
                                            <div class="row">
                                                <div class="col-xl-6 col-12">
                                                    <mat-slide-toggle formControlName="conj_telegram" style="padding-top: 17px;">Telegram</mat-slide-toggle>
                                                </div>
        
                                                <div class="col-xl-6 col-12">
                                                    <mat-slide-toggle formControlName="conj_whatsapp" style="padding-top: 17px;">WhatsApp</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput (blur)="valida($event.target.id, $event.target.value)" id="conj_cpf" type="text" formControlName="conj_cpf" placeholder="CPF *" mask="000.000.000-00">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_documento_identificacao" placeholder="Documento de Indentificação *">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="date" formControlName="conj_nascimento" placeholder="Nascimento *">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-2 col-12">
                                            <mat-form-field class="input100">    
                                                <mat-label>Gênero *</mat-label>
                                                <mat-select formControlName="conj_genero">
                                                    <mat-option value="Masculino">Masculino</mat-option>
                                                    <mat-option value="Feminino">Feminino</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_nacionalidade" placeholder="Nacionalidade *">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-4 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_profissao" placeholder="Profissão">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_trabalho_empresa" placeholder="Empresa onde Trabalha">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="tel" formControlName="conj_trabalho_telefone" placeholder="Telefone do Trabalha" mask="(00) 00000-0000">
                                            </mat-form-field>
                                        </div>
        
                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_trabalho_tempo" placeholder="Tempo de Trabalho">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-xl-3 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="conj_renda_mensal" placeholder="Renda Mensal" mask="separator.2" prefix="R$ " thousandSeparator="." separator=",">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="input100">
                                                <textarea matInput formControlName="conj_trabalho_endereco" placeholder="Endereço do Trabalho" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aw-wizard-step>

                        <!-- Documentos -->
                        <aw-wizard-step stepTitle="" [canExit]="avanca" (stepEnter)= "etapaSeta('Documentos')">
                            <div class= "card rounded-0">
                                <div class="card-header">
                                    <span>Documentos</span><br>
                                    <small class="text-muted">
                                        Envie todos os documentos e comprovantes solicitados. Tire foto ou escaneie.<br>
                                        Precisam estar em formato JPG, JPEG ou PNG.
                                    </small>
                                </div>

                                <div class="card-body">

                                    <!-- Pessoa Jurídica -->
                                    <div class="col-12" *ngIf="(this.formCadastro.controls['tipo_pessoa'].value === 'Jurídica')" style="margin-top: 15px;">
                                        <nav class="navbar navbar-secondary bg-secondary rounded text-white">
                                            <span>Documentos da Empresa</span>
                                        </nav>

                                        <div class="row">
                                            <!-- INI CNPJ -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Cartão de CNPJ <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_cnpj')" formControlName="arq_cnpj" id="arq_cnpj" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_cnpj'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_cnpj'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_cnpj').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_cnpj'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_cnpj: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM CNPJ -->

                                            <!-- INI Inscrição Estadual -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                    Inscrição Estadual <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_insc_estadual')" formControlName="arq_insc_estadual" id="arq_insc_estadual" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_insc_estadual'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_insc_estadual'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_insc_estadual').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_insc_estadual'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_insc_estadual: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Inscrição Estadual -->

                                            <!-- INI Inscrição Municipal -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Inscrição Municipal <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_insc_municipal')" formControlName="arq_insc_municipal" id="arq_insc_municipal" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_insc_municipal'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_insc_municipal'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_insc_municipal').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_insc_municipal'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_insc_municipal: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Inscrição Municipal -->

                                            <!-- INI Comprovante de Endereço -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Comprovante de Endereço <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_pj_endereco')" formControlName="arq_pj_endereco" id="arq_pj_endereco" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_pj_endereco'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_pj_endereco'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_pj_endereco').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_pj_endereco'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_pj_endereco: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Comprovante de Endereço -->

                                            <!-- INI Comprovante de Renda -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Comprovante de Renda <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_pj_renda')" formControlName="arq_pj_renda" id="arq_pj_renda" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_pj_renda'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_pj_renda'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_pj_renda').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_pj_renda'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_pj_renda: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Comprovante de Renda -->
                                        </div>

                                        <br>
                                    </div>
                                    <!-- Pessoa Jurídica -->



                                    <!-- Pessoa Física -->
                                    <div class="col-12" style="margin-top: 15px;">
                                        <nav class="navbar navbar-secondary bg-secondary rounded text-white">
                                            <span *ngIf= "(this.formCadastro.controls['tipo_pessoa'].value === 'Física')">Seus Documentos</span>
                                            <span *ngIf= "(this.formCadastro.controls['tipo_pessoa'].value !== 'Física')">Documentos do Representante Legal</span>
                                        </nav>

                                        <div class="row">
                                            <!-- INI Documento de Identificação -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Documento de Identificação <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_doc_identificacao')" id="arq_doc_identificacao" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_doc_identificacao'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_doc_identificacao'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_doc_identificacao').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_doc_identificacao'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_doc_identificacao: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Documento de Identificação -->

                                            <!-- INI CPF -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        CPF <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_cpf')" id="arq_cpf" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_cpf'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_cpf'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_cpf').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_cpf'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_cpf: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM CPF -->

                                            <!-- INI Comprovante de Endereço -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Comprovante de Endereço <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_endereco')" id="arq_endereco" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_endereco'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_endereco'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_endereco').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_endereco'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_endereco: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Comprovante de Endereço -->

                                            <!-- INI Certidão de Casamento -->
                                            <div class="col-12 col-xl-4" *ngIf="(this.formCadastro.controls['estado_civil'].value !== 'Solteiro(a)')" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Certidão de Casamento <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_cert_casamento')" id="arq_cert_casamento" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_cert_casamento'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_cert_casamento'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_cert_casamento').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_cert_casamento'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_cert_casamento: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Certidão de Casamento -->

                                            <!-- INI Comprovante de Renda -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Comprovante de Renda <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_renda')" id="arq_renda" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_renda'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_renda'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_renda').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_renda'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_renda: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Comprovante de Renda -->

                                        </div>
                                    </div>
                                    <!-- Pessoa Física -->



                                    <!-- Cônjuge -->
                                    <div 
                                        class="col-12" 
                                        style="margin-top: 30px;"
                                        *ngIf=  "
                                                    ((this.formCadastro.controls['estado_civil'].value === 'Casado(a)') && (this.formCadastro.controls['tipo_pessoa'].value === 'Física')) || 
                                                    ((this.formCadastro.controls['rep_estado_civil'].value === 'Casado(a)') && (this.formCadastro.controls['tipo_pessoa'].value === 'Jurídica'))
                                                "
                                    >
                                        <nav class="navbar navbar-secondary bg-secondary rounded text-white">
                                            <span>Documentos do(a) Cônjuge</span>
                                        </nav>

                                        <div class="row">
                                            <!-- INI Documento de Identificação -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Documento de Identificação <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_conj_doc_identificacao')" id="arq_conj_doc_identificacao" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_conj_doc_identificacao'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_conj_doc_identificacao'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_conj_doc_identificacao').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_conj_doc_identificacao'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_conj_doc_identificacao: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Documento de Identificação -->

                                            <!-- INI CPF -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        CPF <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_conj_cpf')" id="arq_conj_cpf" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_conj_cpf'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_conj_cpf'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_conj_cpf').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_conj_cpf'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_conj_cpf: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM CPF -->

                                            <!-- INI Comprovante de Endereço -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Comprovante de Endereço <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_conj_endereco')" id="arq_conj_endereco" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_conj_endereco'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_conj_endereco'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_conj_endereco').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_conj_endereco'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_conj_endereco: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Comprovante de Endereço -->

                                            <!-- INI Comprovante de Renda -->
                                            <div class="col-12 col-xl-4" style="margin-top: 30px;">
                                                <div class="card">
                                                    <div class="card-header bg-lighte">
                                                        Comprovante de Renda <br>
                                                    </div>

                                                    <div class="card-body">
                                                        <input type="file" (change)="onFileChange($event, 'arq_conj_renda')" id="arq_conj_renda" style="display: none" accept="{{ anexosAceitos }}">

                                                        <p *ngIf="(this.formCadastro.controls['arq_conj_renda'].value !== null)">
                                                            {{ arquivoNome(this.formCadastro.controls['arq_arq_conj_rendarenda'].value) }}
                                                        </p>

                                                        <div class="btn-group" role="group">
                                                            <button 
                                                                type="button"
                                                                class="btn btn-sm btn-outline-dark" 
                                                                onclick="document.querySelector('#arq_conj_renda').click()" 
                                                                style="width: 35px;"
                                                            >
                                                                <fa-icon [icon]="faFolderOpen"></fa-icon>
                                                            </button>

                                                            <button 
                                                                class="btn btn-sm btn-outline-dark text-danger" 
                                                                style="width: 35px;"
                                                                [disabled]="(this.formCadastro.controls['arq_conj_renda'].value == null)"
                                                                (click)="this.formCadastro.patchValue({ arq_conj_renda: null })"
                                                            >
                                                                <fa-icon [icon]="faTrash"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- FIM Comprovante de Renda -->

                                        </div>
                                    </div>
                                    <!-- Cônjuge -->

                                </div>

                            </div>
                        </aw-wizard-step>

                        <!-- Imóvel Pretendido -->
                        <aw-wizard-step stepTitle="" (stepEnter)= "etapaSeta('Imovel')">
                            <div class= "card rounded-0">
                                <div class="card-header">
                                    <span>Imóvel Pretendido</span><br>
                                    <small class="text-muted">Dados do imóvel pretendido e demais observações.</small>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-xl-2 col-12">
                                            <mat-form-field class="input100">
                                                <input matInput type="text" formControlName="codigo" placeholder="Código">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="input100">
                                                <textarea matInput formControlName="localizacao" placeholder="Endereço Completo *" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field class="input100">
                                                <textarea matInput rows="5" formControlName="observacoes" placeholder="Observações Gerais" style="resize: none;"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br>

                            <div class="row">
                                <div class="col-12">
                                    <span class="text-danger" style="padding-top: 17px;">Ao enviar os dados, declaro que as informações aqui prestadas são verdadeiras e assumo a inteira responsabilidade pelas mesmas.</span>
                                </div>
                            </div>
                        </aw-wizard-step>
                    </aw-wizard>

                    <div class="row" style="padding-top: 15px;">
                        <div class="col-5">
                            <button type="button" class="btn btn-outline-primary" (click)="salvar()" *ngIf="etapaAtual !== 'Tipo'" title="Salva os dados para edição posterior"><fa-icon [icon]="faSave"></fa-icon></button>
                            <button type="button" class="btn btn-outline-danger"  (click)="limpar()" *ngIf="etapaAtual !== 'Tipo'" title="Limpa todos os campos, para recomeço" style="margin-left: 5px;"><fa-icon [icon]="faTrash"></fa-icon></button>
                        </div>

                        <div class="col-7 text-right">
                            <button type="button" class="btn btn-outline-success" *ngIf="etapaAtual === 'Tipo'" style="margin-left: 5px;" (click)="wizard.goToNextStep();"><fa-icon [icon]="faPlay"></fa-icon>&nbsp; Iniciar</button>

                            <div class="btn-group" role="group" *ngIf="etapaAtual !== 'Tipo'">
                                <button type="button" class="btn btn-outline-primary" *ngIf="etapaAtual !== 'Tipo'" (click)="wizard.goToPreviousStep();"><fa-icon [icon]="faArrowCircleLeft"></fa-icon></button>
                                <button type="button" class="btn btn-outline-primary" *ngIf="etapaAtual !== 'Imovel'" (click)="wizard.goToNextStep();"><fa-icon [icon]="faArrowCircleRight"></fa-icon></button>
                            </div>

                            <button type="button" class="btn btn-outline-success" *ngIf="etapaAtual === 'Imovel'" (click)="enviaForm();" style="margin-left: 5px;"><fa-icon [icon]="faCheck"></fa-icon>&nbsp; Finalizar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>