import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPopper } from 'angular-popper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule, WizardComponent } from 'angular-archwizard';
import { NgxMaskModule } from 'ngx-mask';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicStorageModule } from '@ionic/storage';
import { NgxLoadingModule } from 'ngx-loading';

import { ConfigService } from './config.service';
import { CadastroComponent } from './cadastro/cadastro.component';

@NgModule({
    declarations: [
        AppComponent,
        CadastroComponent
    ],
    imports: [
        NgxMaskModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        NgxPopper,
        NgbModule,
        ArchwizardModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        FontAwesomeModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        IonicStorageModule.forRoot(),
        NgxLoadingModule.forRoot({})
    ],
    providers: [
        ConfigService,
        WizardComponent,
        Storage
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
