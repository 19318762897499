import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MovingDirection, WizardComponent } from 'angular-archwizard';
import { ToastrService } from 'ngx-toastr';
import { Storage } from "@ionic/storage";
import { faTrash, faFolderOpen, faSave, faArrowCircleLeft, faArrowCircleRight, faPlay, faCheck } from '@fortawesome/free-solid-svg-icons';

import { ConfigService } from '../config.service';
import { FuncoesService } from '../funcoes.service';

@Component({
    selector: 'app-cadastro',
    templateUrl: './cadastro.component.html',
    styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit {

    formCadastro: FormGroup;
    etapaAtual: string;
    imobiliariaID: string;

    loading = false;
    enviado = false;

    faSave = faSave;
    faPlay = faPlay;
    faCheck = faCheck;
    faTrash = faTrash;
    faFolderOpen = faFolderOpen;
    faArrowCircleLeft = faArrowCircleLeft;
    faArrowCircleRight = faArrowCircleRight;

    anexosAceitos: string = 'image/x-png,image/jpeg';
/*
    telefoneMascara = '(00) 00000-0000';
    celularMascara  = '(00) 00000-0000';
    rep_telefoneMascara = '(00) 00000-0000';
    rep_celularMascara  = '(00) 00000-0000';
    conj_celularMascara = '(00) 00000-0000';
    trabalho_telefoneMascara      = '(00) 00000-0000';
    conj_trabalho_telefoneMascara = '(00) 00000-0000';
*/
    @ViewChild(WizardComponent) public wizard: WizardComponent;

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private funcoes: FuncoesService,
        private config: ConfigService,
        private http: HttpClient,
        private cd: ChangeDetectorRef,
        public storage: Storage
    ) { }

    ngOnInit(): void {
        const urlParams = new URLSearchParams(window.location.search);
        this.imobiliariaID = urlParams.get('e');

        if (this.imobiliariaID === null || this.imobiliariaID === '') {

        } else {
            this.formCadastro = this.formBuilder.group({
                tipo: new FormControl(),
    
                // Dados Básicos
                tipo_pessoa: new FormControl(),
                nome: new FormControl(),
                mail: new FormControl(),
                telefone: new FormControl(),
                celular: new FormControl(),
                telegram: new FormControl(),
                whatsapp: new FormControl(),
                endereco: new FormControl(),
                preferencia_contato: new FormControl(),
    
                // Pessoa Física
                genero: new FormControl(),
                documento_identificacao: new FormControl(),
                cpf: new FormControl(),
                estado_civil: new FormControl(),
                nascimento: new FormControl(),
                profissao: new FormControl(),
                nacionalidade: new FormControl(),
                trabalho_empresa: new FormControl(),
                trabalho_telefone: new FormControl(),
                trabalho_endereco: new FormControl(),
                trabalho_tempo: new FormControl(),
                renda_mensal: new FormControl(),
                outras_rendas: new FormControl(),
    
                arq_cpf: new FormControl(),
                arq_cert_casamento: new FormControl(),
                arq_doc_identificacao: new FormControl(),
                arq_endereco: new FormControl(),
                arq_renda: new FormControl(),
    
                // Pessoa Jurídica
                insc_estadual: new FormControl(),
                insc_municipal: new FormControl(),
                cnpj: new FormControl(),
    
                arq_insc_estadual: new FormControl(),
                arq_insc_municipal: new FormControl(),
                arq_cnpj: new FormControl(),
                arq_pj_endereco: new FormControl(),
                arq_pj_renda: new FormControl(),
    
                // Responsável pela Empresa
                rep_nome: new FormControl(),
                rep_mail: new FormControl(),
                rep_telefone: new FormControl(),
                rep_celular: new FormControl(),
                rep_telegram: new FormControl(),
                rep_whatsapp: new FormControl(),
                rep_endereco: new FormControl(),
                rep_preferencia_contato: new FormControl(),
                rep_genero: new FormControl(),
                rep_documento_identificacao: new FormControl(),
                rep_cpf: new FormControl(),
                rep_estado_civil: new FormControl(),
                rep_nascimento: new FormControl(),
                rep_profissao: new FormControl(),
                rep_nacionalidade: new FormControl(),
                rep_renda_mensal: new FormControl(),
                rep_outras_rendas: new FormControl(),
    
                arq_rep_cpf: new FormControl(),
                arq_rep_doc_identificacao: new FormControl(),
                arq_rep_cert_casamento: new FormControl(),
                arq_rep_endereco: new FormControl(),
                arq_rep_renda: new FormControl(),
    
                // Cônjuge
                conj_nome: new FormControl(),
                conj_mail: new FormControl(),
                conj_celular: new FormControl(),
                conj_telegram: new FormControl(),
                conj_whatsapp: new FormControl(),
                conj_genero: new FormControl(),
                conj_documento_identificacao: new FormControl(),
                conj_cpf: new FormControl(),
                conj_nascimento: new FormControl(),
                conj_profissao: new FormControl(),
                conj_nacionalidade: new FormControl(),
                conj_renda_mensal: new FormControl(),
                conj_trabalho_empresa: new FormControl(),
                conj_trabalho_telefone: new FormControl(),
                conj_trabalho_endereco: new FormControl(),
                conj_trabalho_tempo: new FormControl(),
    
                arq_conj_cpf: new FormControl(),
                arq_conj_doc_identificacao: new FormControl(),
                arq_conj_endereco: new FormControl(),
                arq_conj_renda: new FormControl(),
    
                // Imóvel
                codigo: new FormControl(),
                localizacao: new FormControl(),
    
                // Observações
                observacoes: new FormControl(),
    
                // Controles Internos
                empresa_id: new FormControl(),
                empresa_nome: new FormControl(),
                empresa_logo: new FormControl(),
                empresa_site: new FormControl(),
                empresa_mail: new FormControl(),
                empresa_fone: new FormControl(),
                caminho: new FormControl()
            });

            this.formReseta();
            this.configuracoesCarrega(this.imobiliariaID);

            /* Carrega dados previamente salvos */
            for (const c in this.formCadastro.controls) {
                this.storage.get('GedaimFichaCadastral' + c).then((val) => {
                    if (val) {
                        this.formCadastro.controls[c].setValue(val);
                    }
                });
            }
        }
    }

    arquivoNome(nome: string) {
        return nome.replace(/^.*[\\\/]/, '');
    }

    avanca: (MovingDirection) => boolean = (direction) => {
        switch (direction) {
            case MovingDirection.Forwards:
                return this.validaCampos();
            case MovingDirection.Backwards:
                return true;
            case MovingDirection.Stay:
                return true;
        }
    }

    configuracoesCarrega(empresaID: string) {
        this.http.get(this.config.confURL + '?e=' + empresaID)
            .subscribe(
                dados => {
                    if (dados['sucesso'] === true) {
                        this.formCadastro.patchValue({
                            empresa_id: dados['id'],
                            empresa_nome: dados['nome'],
                            empresa_logo: dados['logo'],
                            empresa_site: dados['url'],
                            empresa_mail: dados['email'],
                            empresa_fone: dados['telefone']
                        });
                    } else {
                        this.imobiliariaID = null;
                        this.formReseta();

                        this.toastr.error(dados['mensagem'], 'Atenção');
                    }
                },
                erro => {
                    this.imobiliariaID = null;
                    this.formReseta();

                    this.toastr.error(erro['error']['text'], 'lll');
                }
            );
    }

    desmarcaOpcoes(selecionar: string) {
        document.getElementById('Inquilino').classList.remove('selected');
        document.getElementById('Fiador').classList.remove('selected');
        document.getElementById('Locador').classList.remove('selected');

        document.getElementById(selecionar).classList.toggle('selected');

        this.formCadastro.patchValue({
            tipo: selecionar
        });

        this.wizard.goToNextStep();
    }

    enviaForm() {
        if (!this.verificaCampoPreenchido('localizacao')) {
            this.toastr.error('Informe a localização do imóvel.');
            return false;
        }

        this.loading = true;

        this.http.post(this.config.formURL, this.formCadastro.value)
            .subscribe(
                dados => {
                    if (dados['sucesso'] === true) {
                        this.toastr.success('Sua ficha foi enviada com sucesso.', 'Obrigado.');
                        let arquivo = dados['mensagem'];
                        window.open(arquivo);

                        this.loading = false;
                        this.enviado = true;

                        this.formReseta();
                        this.wizard.goToStep(1);
                    } else {
                        this.loading = false;
                        this.toastr.error(dados['mensagem'], 'Ocorreu um erro ao enviar sua ficha.');
                    }
                },
                erro => {
                    this.loading = false;
                    this.toastr.error(erro['error']['text'], 'Ocorreu um erro ao enviar sua ficha.');
                }
            );
    }

    etapaSeta(etapa: string) {
        this.etapaAtual = etapa;
    }

    formReseta() {
        this.formCadastro.reset();

        this.formCadastro.patchValue({
            tipo_pessoa: 'Física',
            preferencia_contato: 'Ligação Telefônica',
            caminho: this.funcoes.geraString(25)
        });
    }

    onFileChange(event, campo: string) {
        const reader = new FileReader();
 
        if(event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.formCadastro.patchValue({
                    [campo]: reader.result
                });
            
                this.cd.markForCheck();
            };
        }
    }

    limpar() {
        for (const c in this.formCadastro.controls) {
            this.storage.set('GedaimFichaCadastral' + c, '');
        }

        this.formReseta();
        this.wizard.reset();
    }

    salvar() {
        for (const c in this.formCadastro.controls) {
            this.storage.set('GedaimFichaCadastral' + c, this.formCadastro.get(c).value);
        }

        this.toastr.success('Dados salvos com sucesso.');
    }

    valida(id: string, valor: string) {
        if (valor !== '') {
            if (id.toLowerCase().includes('mail')) {
                if (!this.funcoes.validaEMail(valor)) {
                    document.getElementById(id).focus();
                }
            }

            if (id.toLowerCase().includes('cpf')) {
                if (!this.funcoes.validaCPF(valor)) {
                    document.getElementById(id).focus();
                }
            }

            if (id.toLowerCase().includes('cnpj')) {
                if (!this.funcoes.validarCNPJ(valor)) {
                    document.getElementById(id).focus();
                }
            }
        }
    }

    validaCampos() {
        let avanca = true;
        let preenchido = false;

        /* Dados Básicos */
        if (this.etapaAtual === 'DadosBasicos') {
            preenchido =    (this.verificaCampoPreenchido('tipo_pessoa')) &&
                            (this.verificaCampoPreenchido('nome')) &&
                            (this.verificaCampoPreenchido('mail')) &&
                            (this.verificaCampoPreenchido('celular')) &&
                            (this.verificaCampoPreenchido('endereco'));

            if (this.verificaCampoPreenchido('mail')) {
                avanca = (this.funcoes.validaEMail(this.formCadastro.get('mail').value));
            }
        }

        /* Dados Complementares */
        if (this.etapaAtual === 'DadosComplementares') {

            /* Pessoa Física */
            if (this.formCadastro.get('tipo_pessoa').value === 'Física') {
                preenchido =    (this.verificaCampoPreenchido('cpf')) &&
                                (this.verificaCampoPreenchido('documento_identificacao')) &&
                                (this.verificaCampoPreenchido('nascimento')) &&
                                (this.verificaCampoPreenchido('genero')) &&
                                (this.verificaCampoPreenchido('estado_civil')) &&
                                (this.verificaCampoPreenchido('nacionalidade')) &&
                                (this.verificaCampoPreenchido('profissao')) &&
                                (this.verificaCampoPreenchido('trabalho_empresa')) &&
                                (this.verificaCampoPreenchido('trabalho_telefone')) &&
                                (this.verificaCampoPreenchido('trabalho_tempo')) &&
                                (this.verificaCampoPreenchido('renda_mensal'));

                if (this.verificaCampoPreenchido('cpf')) {
                    avanca = (this.funcoes.validaCPF(this.formCadastro.get('cpf').value));
                }
            }

            /* Pessoa Jurídica */
            if (this.formCadastro.get('tipo_pessoa').value === 'Jurídica') {
                preenchido =    (this.verificaCampoPreenchido('cnpj')) &&
                                (this.verificaCampoPreenchido('rep_nome')) &&
                                (this.verificaCampoPreenchido('rep_mail')) &&
                                (this.verificaCampoPreenchido('rep_celular')) &&
                                (this.verificaCampoPreenchido('rep_preferencia_contato')) &&
                                (this.verificaCampoPreenchido('rep_endereco')) &&
                                (this.verificaCampoPreenchido('rep_cpf')) &&
                                (this.verificaCampoPreenchido('rep_documento_identificacao')) &&
                                (this.verificaCampoPreenchido('rep_nascimento')) &&
                                (this.verificaCampoPreenchido('rep_genero')) &&
                                (this.verificaCampoPreenchido('rep_estado_civil')) &&
                                (this.verificaCampoPreenchido('rep_nacionalidade')) &&
                                (this.verificaCampoPreenchido('rep_profissao')) &&
                                (this.verificaCampoPreenchido('rep_renda_mensal'));

                if (this.verificaCampoPreenchido('cnpj')) {
                    avanca = (this.funcoes.validarCNPJ(this.formCadastro.get('cnpj').value))
                }
            }

            /* Dados do Cônjuge */
            if (
                ((this.formCadastro.get('tipo_pessoa').value === 'Física') && (this.formCadastro.get('estado_civil').value === 'Casado(a)')) ||
                ((this.formCadastro.get('tipo_pessoa').value === 'Jurídica') && (this.formCadastro.get('rep_estado_civil').value === 'Casado(a)'))
            ) {
                preenchido =    preenchido &&
                                (this.verificaCampoPreenchido('conj_nome')) &&
                                (this.verificaCampoPreenchido('conj_celular')) &&
                                (this.verificaCampoPreenchido('conj_cpf')) &&
                                (this.verificaCampoPreenchido('conj_mail')) &&
                                (this.verificaCampoPreenchido('conj_documento_identificacao')) &&
                                (this.verificaCampoPreenchido('conj_nascimento')) &&
                                (this.verificaCampoPreenchido('conj_genero')) &&
                                (this.verificaCampoPreenchido('conj_nacionalidade'));

                if (this.verificaCampoPreenchido('conj_cpf')) {
                    avanca = (this.funcoes.validaCPF(this.formCadastro.get('conj_cpf').value));
                }

                if (this.verificaCampoPreenchido('conj_mail')) {
                    avanca = this.funcoes.validaEMail(this.formCadastro.get('conj_mail').value);
                }
            }
        }

        /* Documentos */
        if (this.etapaAtual === 'Documentos') {
            preenchido = true;
        }

        /* Imóvel */
        if (this.etapaAtual === 'Imovel') {
            preenchido = (this.verificaCampoPreenchido('localizacao'));
        }

        /* Retorno */
        if (preenchido === false) {
            this.toastr.error('Campos com "*" são obrigatórios.');
        }

        return avanca && preenchido;
    }

    verificaCampoPreenchido(campo: string) {
        if ((this.formCadastro.get(campo).value === null) || (this.formCadastro.get(campo).value === '')) {
            this.formCadastro.controls[campo].setErrors({ 'incorrect': true });
            return false;
        } else {
            this.formCadastro.controls[campo].markAllAsTouched();
        }

        return true;
    }
}
