import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    formURL = "https://cadastro.gedaim.com/ws/fichas/form.php";
    confURL = "https://cadastro.gedaim.com/ws/fichas/conf.php";

    constructor() { }
}
