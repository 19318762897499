import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})

export class FuncoesService {

    constructor(
        private toastr: ToastrService
    ) { }

    geraString(tamanho: number) {
        var result: string = '';
        var characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < tamanho; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }    

    digitosIguais(numero: string) {
        return !!numero.match(/^(\d)\1*$/);
    }

    validaEMail(conteudo: string) {
        if (conteudo === '') {
            return false;
        }

        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        if (!regexp.test(conteudo)) {
            this.toastr.error('E-Mail inválido.');
            return false;
        } else {
            return true;
        }

    }

    validarCNPJ(conteudo: string) {
        conteudo = conteudo.replace(/[^\d]+/g,'');
     
        if (conteudo == '') {
            return false;
        }
         
        if (conteudo.length != 14) {
            this.toastr.error('CNPJ inválido.');
            return false;
        }

        if (this.digitosIguais(conteudo)) {
            this.toastr.error('CNPJ inválido.');
            return false;
        }

        let tamanho = conteudo.length - 2;
        var numeros = conteudo.substring(0, tamanho);
        var digitos = conteudo.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
          soma += +numeros.charAt(tamanho - i) * pos--;

             if (pos < 2) {
                pos = 9;
            }
        }

        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado != +digitos.charAt(0)) {
            this.toastr.error('CNPJ inválido.');
            return false;
        }
             
        tamanho = tamanho + 1;
        numeros = conteudo.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
          soma += +numeros.charAt(tamanho - i) * pos--;

            if (pos < 2) {
                pos = 9;
            }
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado != +digitos.charAt(1)) {
            this.toastr.error('CNPJ inválido.');
            return false;
        }
               
        return true;        
    }

    validaCPF(conteudo: string) {
        var soma: number = 0, 
            resto: number, 
            i: number;

        conteudo = conteudo.replace(/[^\d]+/g,'');
    
        if (conteudo == '') {
            return false;
        }
            
        if (conteudo.length != 11) {
            this.toastr.error('CPF inválido.');
            return false;
        }

        if (this.digitosIguais(conteudo)) {
            this.toastr.error('CPF inválido.');
            return false;
        }

        for (i=1; i<=9; i++) soma = soma + parseInt(conteudo.substring(i - 1, i)) * (11 - i);

        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11)) {
            resto = 0;
        }

        if (resto != parseInt(conteudo.substring(9, 10))) {
            this.toastr.error('CPF inválido.');
            return false;
        }

        soma = 0;
        
        for (i = 1; i <= 10; i++) {
            soma = soma + parseInt(conteudo.substring(i - 1, i)) * (12 - i);
        }
        
        resto = (soma * 10) % 11;
        
        if ((resto == 10) || (resto == 11)) {
            resto = 0;
        }

        if (resto != parseInt(conteudo.substring(10, 11))) {
            this.toastr.error('CPF inválido.');
            return false;
        }

        return true;
    }
}
